const HowToUseIt = () => {
  return(
    <>
      <div className="home-3-box">
        <div className="container">
          <div className="row row-flex justify-content-center align-items-center">
            <div className="col-md-12 py-5 text-center">
              <h1 className="font-weight-bold slgo-small-title">Trova un centro medico<br />con <span className="slgo-span-logo">salute<span>go</span></span></h1>
              <p className="text-center">Salutego è il servizio che ti permette velocemente di controllare in tutto il territorio nazionale<br />
              quale <strong>struttura</strong> faccia al caso tuo per le <strong>prestazioni sanitarie</strong> di cui hai bisgono</p>
            </div>
            <div className="col-md-4 text-center pb-5">
              <img src="/img/prestazioni.png" width="280" height="280" className="slgo-img-h-auto my-4" alt="prestazioni sanitarie"/>
              <p className="text-center">Cerca tra tutte le<br /><strong>prestazioni mediche</strong> disponibili</p>
            </div>
            <div className="col-md-4 text-center pb-5">
              <img src="/img/centri-medici.png" width="280" height="280" className="slgo-img-h-auto my-4" alt="centri medici, ambulatori e poliambulatori"/>
              <p className="text-center">Confronta e eleziona<br />la <strong>struttura sanitaria migliore</strong></p>
            </div>
            <div className="col-md-4 text-center pb-5">
              <img src="/img/contatti.png" width="280" height="280" className="slgo-img-h-auto my-4" alt="contatta o prenota"/>
              <p className="text-center"><b>Contatta</b> il centro medico<br />o <strong>prenota subito</strong> la tua visita</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}

export default HowToUseIt;