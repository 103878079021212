import { Helmet } from 'react-helmet-async';
import Capitalize from "../../utilities/Capitalize";

const CentersSeo = (props) => {

  let pageUrl = window.location.href;
  let name = "";
  let title = "SaluteGo - Trova il centro medico più vicino";
  let description = "Trova il centro medico più vicino a te, filtrando per regione, provincia e comune, oppure cercandolo in base alle prestazioni sanitarie.";

  if (props.seoPage === "CENTERS") {
    title = "SaluteGo - Trova il centro medico più vicino";
  } if (props.seoPage === "LOCALITY") {
    if (props.localityName === props.provinceName) {
      name = Capitalize(props.localityName);
      title = "SaluteGo - Trova centro medico " + name;
      description = "Trova il centro medico a " + name + " più vicino a te, filtrando in base alle prestazioni sanitarie che ti interessano.";
    } else {
      name = Capitalize(props.localityName) + ' in provincia di ' + Capitalize(props.provinceName);
      title = "SaluteGo - Trova centro medico " + name;
      description = "Trova il centro medico più vicino a te nel comune di " + name + ", filtrando in base alle prestazioni sanitarie che ti interessano.";
    }
  } if (props.seoPage === "PROVINCE") {
    name = Capitalize(props.provinceName);
    title = "SaluteGo - Trova un centro medico in provincia di " + name;
    description = "Trova il centro medico più vicino a te in provincia di " + name + ", filtrando in base alle prestazioni sanitarie che ti interessano.";
  } if (props.seoPage === "REGION") {
    name = Capitalize(props.regionName);
    title = "SaluteGo - Trova il centro medico in " + name;
    description = "Trova il centro medico più vicino a te in " + name + ", filtrando per provincia e comune, oppure cercandolo in base alle prestazioni sanitarie.";
  } if (props.seoPage === "SEARCH" ) {
    const params = new URLSearchParams(window.location.search);
    name = params.get('nome');
    {/* name = props.keyText; */}
    name = name ? Capitalize(name.replace(/\./g, "")) : '';
    title = `SaluteGo - Ricerca centro medico che contiene la parola ${name}`;
    description = "Trova il centro medico più vicino a te che contiene la parola " + name + ".";
  }

  return(
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={pageUrl} />
        <link rel="canonical" href={pageUrl} />
        <meta property="og:image" content="https://salutego.it/img/cart-trova-centro-medico.jpg" />
        <meta name="twitter:image" content="https://salutego.it/img/cart-trova-centro-medico.jpg" />
        <meta name="twitter:description" content={description} />
      </Helmet>
    </>
  );
}

export default CentersSeo;