import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import ScrollToElement from "../../utilities/ScrollToElement";

// const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

function updateUrl(page) {
  const currentUrl = new URL(window.location);
  if (page === 1) {
    currentUrl.searchParams.delete('page');
  } else {
    currentUrl.searchParams.set('page', page);
  }
  window.history.replaceState(null, '', currentUrl.toString());
}

function PaginatedItems({ itemsPerPage, setPage, totalCount, totalPages, currentPage, scrollElement }) {

  const handlePageClick = (event) => {
    const newPage = event.selected + 1;
    setPage(newPage);
    ScrollToElement(scrollElement);
    updateUrl(newPage);
  };

  useEffect(() => {
    const url = new URL(window.location);
    if (url.searchParams.get('page') === '1') {
      url.searchParams.delete('page');
      window.history.replaceState(null, '', url.toString());
    }
  }, []);

  return (
    <nav className="nav d-flex justify-content-center">
      <ReactPaginate
        breakLabel="..."
        nextLabel="&raquo;"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={totalPages}
        previousLabel="&laquo;"
        renderOnZeroPageCount={null}
        className="pagination pagination-sm flex-sm-wrap slgo-centers-pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        activeClassName="active"
        nextClassName="page-link"
        previousClassName="page-link"
        forcePage={currentPage - 1}
      />
    </nav>
  );
}


const Paginator = (props) => {
return(
  <>
    <PaginatedItems 
      itemsPerPage={props.itemsPerPage}
      setPage={props.setPage}
      totalCount={props.totalCount}
      totalPages={props.totalPages}
      currentPage={props.currentPage}
      scrollElement={props.scrollElement}
    />
  </>
);

}

export default Paginator;