import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CategoryInput from "./CategoryInput";
import PlacesInput from "./PlacesInput";
// import GetUserCoords from "../../../utilities/GetUserCoords";
// import useUserLatLng from "../../../utilities/useUserLatLng";

const SearchSolutions = () => {

  const [categoryName, setCategoryName] = useState('');
  const [categoryId, setCategoryId] = useState();
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [formattedAddress, setFormattedAddress] = useState();
  const [disabled, setDisabled] = useState(true);
  const [userGeolocated, setUserGeolocated] = useState(false);

  // TODO: find a way for setting the autofocus on Cerca button
  // so that the user can simply click enter
  // const handleKeyDown = e => {
  //   //Enter keyboard is 13
  //   if (e.keyCode === 13) {
  //     onClick();
  //   }
  // }

  let navigate = useNavigate();
  const onClick = () => {
    navigate(`/trova-centro-medico-vicino/risultati?categoryId=${categoryId}&categoryName=${categoryName}&lat=${lat}&lng=${lng}&address=${formattedAddress}`);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          setUserGeolocated(true);
          setFormattedAddress("posizione corrente");
        },
        (error) => {
          console.log("Unable to retrieve your location");
        }
      );
    } else {
      console.log("Geolocation not supported");
    }
  }, []);

  return(
    <>
      {/* TODO: Unable to use the automatic-geolocation simply using the lib: the google api key must be restricted by website url or ip address, but the request comes from the user's device and the ip is unknown:
      - https://github.com/marlove/react-native-geocoding/issues/18#issue-421437563
      - https://github.com/marlove/react-native-geocoding/issues/40
      The only solution could be to develop an API in the backend and call it
      <GetUserCoords userGeolocated={userGeolocated} setUserGeolocated={setUserGeolocated} setLat={setLat} setLng={setLng}
        setFormattedAddress={setFormattedAddress} />*/}
      <div className="slider-home">
        <div className="container">
          <div className="row row-flex justify-content-center align-items-center">
            <div className="col-md-12 pt-4 pb-2 pt-md-5 pb-md-5">
              <div className="slgo-insalute mt-3">
                <p className="text-end mb-1"><img src="/img/logo/insalute-white.png" width="40" height="40" alt="InSalute.net"/> InSalute.net diventa</p>
              </div>
              <img className="mb-4" src="/img/logo/logo-white.svg" width="400" height="100" alt="SaluteGO"/>
              <h4>Il motore di ricerca sanitario che unisce <b>pubblico</b> e <b>privato</b></h4>
            </div>
            <div className="slgo-max-w-906 col-md-12">
              <div className="col-search-input col-search-input-align-left py-2">
                <CategoryInput categoryName={categoryName} setCategoryName={setCategoryName} setCategoryId={setCategoryId} formattedAddress={formattedAddress} categoryId={categoryId} setDisabled={setDisabled} />
              </div>
              <div className="col-search-input col-search-input-align-left py-2">
                <PlacesInput setLat={setLat} setLng={setLng} setFormattedAddress={setFormattedAddress}
                  userGeolocated={userGeolocated} setUserGeolocated={setUserGeolocated} />
              </div>
            </div>
            <div className="col-md-12 py-4 py-md-5">
              <div className="col-search-input">
                <button disabled={disabled} type="button" className="btn btn-primary btn-no-arrow slgo-btn-search-home slgo-btn-search" onClick={onClick} autoFocus>Cerca</button>
              </div>
            </div>
          </div>
        </div>
        <small className="slgo-light-text slgo-go-down">Oppure esplora il servizio</small>
      </div>
    </>
  );
}

export default SearchSolutions;