import useFetch from "../../parts/useFetch";
import { Link } from "react-router-dom";
import NumberWithDot from "../../../utilities/NumberWithDot";

const CentersCard = () => {

  const url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/centers`;

  const {headers, error, loading} = useFetch(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) console.log(error)
  if (!headers?.['total-count']) {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>
      </div>
      </>
    );
  }

  return(
    <>
    <h4 className="slgo-small-title font-weight-bold">Centri medici</h4>
      <h6 className="slgo-small-title"><b>{NumberWithDot(headers?.['total-count'])}</b> <strong>strutture</strong> e <strong>ambulatori</strong> distribuiti in tutta Italia</h6>
      <Link to={'/trova-centro-medico'} className="btn btn-primary mt-5 slgo-white-btn float-end">Scopri le strutture</Link>
      <div className="clearfix"></div>
    </>
  );
}

export default CentersCard;