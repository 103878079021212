import { useState } from "react";
import useFetch from "../parts/useFetch";
import { Link } from "react-router-dom";
import CleanUpUrl from "../../utilities/CleanUpUrl";
import Paginator from "../parts/Paginator";
import NumberWithDot from "../../utilities/NumberWithDot";

const CategoriesList = (props) => {

  const handleSubmit = e => {
    setPage('');
    setText(tmpText);
  }

  const handleKeyDown = e => {
    //Enter keyboard is 13
    if (e.keyCode === 13) {
      handleSubmit();
    }
  }

  const [text, setText] = useState("");
  const [tmpText, setTmpText] = useState("");
  const [page, setPage] = useState(props.page);

  let url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/categories`;
  if (page || text) url = url + "?"
  if (page) url = url + `&page=${page}`
  if (text) url = url + `&search=${text}`
  CleanUpUrl(url);

  const {data, headers, loading, error} = useFetch(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (!data) {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>
      </div>
      </>
    );
  }
  if (error) console.log(error);

  var pageIndexMsg = "";
  if (page && page !== '1') pageIndexMsg = ` ( pagina ${page} )`

  return(
    <>
      <div className="single-center-content single-center-content-search-form">
        <div className="container slgo-container-search-center-exam slgo-mobile-w-94 py-5 px-0" id="center-services-filter">
          <div className="row g-5">
            <div className="col-md-12">
                <div className="col-md-12 d-sm-flex col-search-input">
                  <input
                    id="textInput"
                    className="mb-3 mb-sm-0"
                    type="name"
                    value={tmpText}
                    onInput={e => setTmpText(e.target.value)}
                    placeholder="Trova i centri medici per prestazione"
                    autoComplete="off"
                    onKeyDown={handleKeyDown}
                  />
                  <button type="button" className="btn btn-primary btn-no-arrow float-end slgo-light-text slgo-btn-search ms-3" onClick={handleSubmit}>Cerca</button>
                </div>
            </div>
          </div>
        </div>
      </div>
      {data?.data.length < 1 ? (
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. La parola inserita non ha nessuna corrispondenza</p>
      </div>
      ) : (
        <div className="container slgo-container-center-services py-5">
        <p>Sono state trovate <b>{NumberWithDot(headers?.['total-count'])}</b> prestazioni mediche di cui {data?.data.length} visualizzate<i>{pageIndexMsg}</i></p>
        <div className="row g-5">
          <div className="col-md-12">
          {data?.data.map((category) => (
            <p className="mb-4 py-3 px-4"><Link to={"/trova-centro-medico-con-prestazione/" + category.slug}>{category.category_name}</Link></p>
          ))}
          </div>
          <div className="col-md-12">
            <Paginator
              setPage={setPage}
              totalCount={parseInt(headers?.['total-count'])}
              itemsPerPage={parseInt(headers?.['total-count'])}
              totalPages={parseInt(headers?.['total-pages'])}
              currentPage={parseInt(headers?.['current-page'])}
              scrollElement='center-services-filter'
            />
          </div>
        </div>
      </div>
      )}
    </>
  );
}

export default CategoriesList;