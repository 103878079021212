import Capitalize from "../../../utilities/Capitalize";
import Titleize from "../../../utilities/Titleize";

const CentersServiceTitle = ({categoryName, nearToAddress}) => {
  return(
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12 pb-0">
            <h1 className="h2 mb-3">{Titleize(categoryName)}</h1>
            <p className="slgo-near-to mb-0"><b>Vicino a: {Capitalize(nearToAddress)}</b></p>
          </div>
        </div>
      </div>
  </>
  );
}

export default CentersServiceTitle;