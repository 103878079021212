import React from 'react';

{/*

installare pacchetto Bootstrap icons??

npm install react-bootstrap-icons
import { Check } from 'react-bootstrap-icons';

*/}

const CheckIcon = () => (
    <svg className="bi" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" fill="var(--slgo-main-green)" d="M17.854 5.646a1.5 1.5 0 0 1 0 2.122l-8 8a1.5 1.5 0 0 1-2.122 0l-4-4a1.5 1.5 0 0 1 2.122-2.122l3.293 3.293 6.293-6.293a1.5 1.5 0 0 1 2.122 0z" />
    </svg>
);

export default CheckIcon;