import useFetch from "../parts/useFetch";

function extractAreaName(slug, base_path) {
  return slug.split(base_path).slice(-1)[0].replaceAll("-", " ")
}

function getAuthoritiesUrl(slug, baseUrl) {

  const region_base_path = "in-"
  const province_base_path = "in-provincia-di-"
  const locality_base_path = "nel-comune-di-"

  if (slug.includes(province_base_path)) {
    baseUrl = baseUrl + "provinces?province_name="
    const province_name = extractAreaName(slug, province_base_path);
    return baseUrl + province_name;
  } else if (slug.includes(region_base_path)) {
    baseUrl = baseUrl + "regions?region_name="
    const region_name = extractAreaName(slug, region_base_path);
    return baseUrl + region_name;
  } else if(slug.includes(locality_base_path)) {
    baseUrl = baseUrl + "localities?locality_name="
    const locality_name = extractAreaName(slug, locality_base_path);
    return baseUrl + locality_name;
  }
  return null;
}

function DecodeGeopointIds (slug) {
  let regionId = null
  let provinceId = null
  let localityId = null
  let noPage = null
  const publicCenter = null

  let baseUrl = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/`
  
  let url = getAuthoritiesUrl(slug, baseUrl);
  
  const {data, loading, error} = useFetch(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) console.log(error);
  if (!data?.data) {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>
      </div>
      </>
    );
  }

  if (url === null) {
    noPage = 1
  }

  if (url.includes("/regions?")) {
    regionId = data?.data[0].id
  } else if (url.includes("/provinces?")) {
    provinceId = data?.data[0].id
    regionId = data?.data[0].region_id
  } else if (url.includes("/localities?")) {
    localityId = data?.data[0].id
    provinceId = data?.data[0].province_id
    regionId = data?.data[0].region_id
  }

  return { regionId, provinceId, localityId }
}

export default DecodeGeopointIds;
