import NumberWithDot from "../../utilities/NumberWithDot";
import useFetch from "../parts/useFetch";

const CategoriesSliderTop = () => {
  const url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/categories`;

  const {headers, loading, error} = useFetch(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) return console.log(error);
  if (!headers) {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>
      </div>
      </>
    );
  }

  function roundToThousands(number) {
    if (!number) return 0;
    return Math.floor(number / 1000) * 1000;
  }
  const formattedCount = roundToThousands(headers?.['total-count']);

  return(
    <>
      <div className="slider slider-top slider-top-yellow">
        <div className="container">
          <div className="row row-flex justify-content-center align-items-center">
            <div className="col-md-7 flex-fill my-4">
              <h1 className="h1 slgo-small-title font-weight-bold">Prestazioni sanitarie</h1>
              <h2 className="slgo-small-title">Il motore di ricerca con oltre <span className="font-weight-bold">{NumberWithDot(formattedCount)}</span> prestazioni sanitarie</h2>
            </div>
            <div className="col-md-5"><img src="/img/header-image-prestazioni.png" alt="prestazioni centri medici" /></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoriesSliderTop;