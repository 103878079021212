import { BrowserRouter, Routes, Route, useParams, useLocation, Navigate } from "react-router-dom";
import React from "react";
import Layout from "./pages/Layout";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Categories from "./pages/Categories/Categories";
import Centers from "./pages/Centers/Centers";
import SignUp from "./pages/SignUp/SignUp";
import PageNotFound from "./pages/PageNotFound";
import Center from "./pages/Center/Center";
import CentersCategory from "./pages/Centers/CentersCategory/CentersCategory";
import Service from "./pages/Service/Service";
import './assets/dist/css/bootstrap.min.css';
import './assets/dist/css/colors.css';
import './assets/dist/css/global.css';
import './assets/dist/css/responsive.css';
import DecodeGeopointIds from "./pages/parts/DecodeGeopointIds";
import CentersService from "./pages/Centers/CentersService/CentersService";
import TermsOfUsage from "./pages/TermsOfUsage";
import ScrollToTop from "./utilities/ScrollToTop";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function CentersQuery() {
  let query = useQuery();
  let publicCenter = null;

  if (window.location.href.includes('public')) publicCenter = query.get("public");
  return(<Centers publicCenter={publicCenter} page={query.get("page")} />);
}

function CategoriesQuery() {
  let query = useQuery();
  return(<Categories page={query.get("page")}/>);
}

function CenterQuery() {
  const { slug } = useParams();
  let query = useQuery();
  return(<Center slug={slug} page={query.get("page")}/>);
}

function CentersSlug() {
  const { slug } = useParams();
  let query = useQuery();
  let publicCenter = null;

  if (slug.includes('cerca')) {
    return(<Centers regionId={null} provinceId={null} localityId={null} keyText={query.get("nome")} activeTab="key" page={query.get("page")} />);
  }
  if (window.location.href.includes('public')) publicCenter = query.get("public");

  const { regionId, provinceId, localityId } = DecodeGeopointIds(slug);
  if (regionId !== undefined || provinceId !== undefined || localityId !== undefined ) {
    return(<Centers regionId={regionId} provinceId={provinceId} localityId={localityId} keyText={null} activeTab="geo" publicCenter={publicCenter} page={query.get("page")} />);
  } else {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>{/* dati in caricamento */}
      </div>
      </>
    )
  }
}

function CentersCategorySlug() {

  var { filterParams } = useParams();
  const { category } = useParams();
  let query = useQuery();
  let publicCenter = null;

  if (window.location.href.includes('public')) publicCenter = query.get("public");

  if (!filterParams) {
    return(<CentersCategory activeTab="geo" publicCenter={publicCenter} categorySlug={category} page={query.get("page")} />);
  }

  if (filterParams.includes('cerca')) {
    return(<CentersCategory regionId={null} provinceId={null} localityId={null} keyText={query.get("nome")} activeTab="key" categorySlug={category} page={query.get("page")} />);
  }

  filterParams = filterParams.includes("#") ? filterParams.split("#")[0] : filterParams;
  const { regionId, provinceId, localityId } = DecodeGeopointIds(filterParams);
  if (regionId !== undefined || provinceId !== undefined || localityId !== undefined ) {
    return(<CentersCategory regionId={regionId} provinceId={provinceId} localityId={localityId} activeTab="geo" publicCenter={publicCenter} categorySlug={category} page={query.get("page")}/>);
  } else {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>{/* dati in caricamento */}
      </div>
      </>
    )
  }
}

export default function App() {
  return (
    <>
      <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="il-servizio" element={<About />} />
              <Route path="prestazioni" element={<CategoriesQuery />} />
              <Route path="prestazioni/:query" element={<CategoriesQuery />} />
              <Route path="trova-centro-medico" element={<CentersQuery />} />
              <Route path="trova-centro-medico/:slug" element={<CentersSlug />} />
              <Route path="trova-centro-medico-con-prestazione/:category" element={<CentersCategorySlug />} />
              <Route path="trova-centro-medico-con-prestazione/:category/:filterParams" element={<CentersCategorySlug />} />
              <Route path="/trova-centro-medico-vicino/:searchParams" element={<CentersService />} />
              <Route path="centro-medico/:slug" element={<CenterQuery />} />
              <Route path="prestazione-medica/:slug" element={<Service />} />
              <Route path="offri-un-servizio-sanitario" element={<SignUp />} />
              <Route path="condizioni-uso" element={<TermsOfUsage />} />
              <Route path="/404" element={<PageNotFound />} />
              <Route path="*" element={<Navigate to="/404" />} />
            </Route>
          </Routes>
      </BrowserRouter>
    </>
  );
}
