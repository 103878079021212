import { useState } from "react";
import useFetch from "./useFetch";
import { Link } from "react-router-dom";
import CleanUpUrl from "../../utilities/CleanUpUrl";
import Paginator from "./Paginator";
import NumberWithDot from "../../utilities/NumberWithDot";

const CenterServicesList = (props) => {

  const handleSubmit = e => {
    setText(tmpText);
  }

  const handleKeyDown = e => {
    //Enter keyboard is 13
    if (e.keyCode === 13) {
      handleSubmit();
    }
  }

  const [text, setText] = useState("");
  const [tmpText, setTmpText] = useState("");
  const [page, setPage] = useState(props.page);

  let url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/services?center_id=${props.centerId}`;
  if (page) url = url + `&page=${page}`
  if (text) url = url + `&service_name=${text}`
  CleanUpUrl(url);

  const {data, headers, loading, error} = useFetch(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (!data) {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>
      </div>
      </>
    );
  }
  if (error) console.log(error);

  if (data?.data.length === 0) {
    return(
      <>
        <div className="single-center-content single-center-content-search-form">
          <div className="container slgo-container-search-center-exam slgo-mobile-w-94 pb-5 px-0 mb-5">
            <div className="row g-5">
              <div className="col-md-12 mt-3">
                <h5 className="slgo-light-text font-weight-bold">Al momento non abbiamo prestazioni sanitarie per il Centro Medico</h5>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  var pageIndexMsg = "";
  if (page && page !== 1) pageIndexMsg = ` ( pagina ${page} )`

  return(
    <>
      <div className="single-center-content single-center-content-search-form">
        <div className="container slgo-container-search-center-exam slgo-mobile-w-94 pb-5 px-0">
          <div className="row g-5">
            <div className="col-md-12 mt-3">
              <h5 className="slgo-light-text font-weight-bold">Tutte le prestazioni sanitarie disponibili presso il Centro Medico</h5>
            </div>
            <div className="col-md-12 mt-2">
                <div className="col-md-12 d-sm-flex col-search-input">
                  <input
                    id="textInput"
                    className="mb-3 mb-sm-0"
                    type="name"
                    value={tmpText}
                    onInput={e => setTmpText(e.target.value)}
                    placeholder="Trova l'esame che stai cercando"
                    autoComplete="off"
                    onKeyDown={handleKeyDown}
                  />
                  <button type="button" className="btn btn-primary btn-no-arrow float-end slgo-light-text slgo-btn-search ms-3" onClick={handleSubmit}>Cerca</button>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container slgo-container-center-services py-5">
      <p><i>{pageIndexMsg}</i></p>
        <div className="row g-5">
          <div className="col-md-12">
          {data?.data.map((service) => (
            <p className="mb-4 py-3 px-4"><Link to={"/prestazione-medica/" + service.slug}>{service.service_name}</Link></p>
          ))}
          </div>

          <div className="col-md-12">
            <Paginator
              setPage={setPage}
              totalCount={parseInt(headers?.['total-count'])}
              itemsPerPage={parseInt(headers?.['total-count'])}
              totalPages={parseInt(headers?.['total-pages'])}
              currentPage={parseInt(headers?.['current-page'])}
              scrollElement='center-map'
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CenterServicesList;