import CategoriesCard from "./CategoriesCard";
import CentersCard from "./CentersCard";

const Resources = () => {
  return (
    <>
      <div className="home-resources">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12 pb-3">
                <h4 className="slgo-light-text font-weight-bold text-center">Cerchi una prestazione sanitaria o un centro medico?</h4>
            </div>

            <div className="col-md-6 mb-3">
              <div className="home-resources-box home-resources-box-yellow pt-4 pb-3 ps-5 pe-3">
                <CategoriesCard />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="home-resources-box home-resources-box-green pt-4 pb-3 ps-5 pe-3">
                <CentersCard />
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  );
}

export default Resources;