import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const PageNotFound = () => {

  const pageUrl = window.location.href;
  const title = "SaluteGo - Pagina non trovata";
  const description = "La pagina che stavi cercando non esiste.";

  return(
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={pageUrl} />
        <link rel="canonical" href={pageUrl} />
        <meta property="og:image" content="https://salutego.it/img/logo/salutego.png" />
        <meta name="twitter:image" content="https://salutego.it/img/logo/salutego.png" />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <div className="slider-404">
        <div className="container">
          <div className="row row-flex justify-content-center align-items-center">
            <div className="col-md-12 pt-5 pt-md-5 pb-md-5">
              <img className="my-5" src="/img/logo/logo-white.svg" width="180" height="45" alt="SaluteGO"/>
              <h1 className="pb-3">Errore 404</h1>
              <h4>La pagina richiesta non esiste.</h4>
            </div>
            <div className="col-md-12 py-4 py-md-5">
              <div className="col-search-input">
                <Link to="/" className="btn btn-primary btn-no-arrow slgo-btn-404">Vai alla Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;