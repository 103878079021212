import { useContext } from "react";
import { SearchContext } from "./CentersBody";
import ScrollToElement from "../../utilities/ScrollToElement";

const SearchFilterKeyText = ({activeKeyTab, keyText, baseUrl, setPage}) => {

  const pageBaseUrl = baseUrl

  const contextValue = useContext(SearchContext);
  let tmpKeyText = keyText;

  function setTmpKeyText(filledText) {
    tmpKeyText = filledText;
  }

  const handleKeyDown = e => {
    //Enter keyboard is 13
    if (e.keyCode === 13) {
      onClick();
    }
  }

  const onClick = () => {
    contextValue.setKeyText(tmpKeyText)
    contextValue.setActiveTab("key");
    setPage('');

    ScrollToElement('center-map');

    window.history.replaceState(null, "UpdatedUrl", `${pageBaseUrl}/cerca?nome=${tmpKeyText}`);
  }

  return(
    <>
      <div className={"tab-pane fade" + (activeKeyTab ? "show active" : "")} id="nav-key" role="tabpanel" aria-labelledby="nav-key-tab">
        <div className="container">
          <div className="row row-flex">
            <div className="col-md-12 d-sm-flex col-search-input">
              <input
                id="keyInput"
                className="mb-3 mb-sm-0"
                type="name"
                defaultValue={keyText}
                onInput={e => setTmpKeyText(e.target.value)}
                placeholder="Cerca per parola chiave"
                autoComplete="off"
                onKeyDown={handleKeyDown}
              />
              <button type="button" className="btn btn-primary btn-no-arrow float-end slgo-light-text slgo-btn-search ms-3" onClick={onClick}>Cerca</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchFilterKeyText;