import AutoComplete from "react-google-autocomplete";

 /*
    Autocomplete is quite simple but old:https://www.npmjs.com/package/react-autocomplete
    Future use: consider ReactSearchAutocomplete instead https://www.npmjs.com/package/react-search-autocomplete

    Note:
    1. for uninstall Autocomplete component, execute the following:
       npm uninstall react-autocomplete --legacy-peer-deps
    2. remember to remove "--legacy-peer-deps" in npm ci command in Dockerfile
  */

const PlacesInput = ({setLat, setLng, setFormattedAddress, userGeolocated, setUserGeolocated}) => {
  var placeHolder="        Digita indirizzo o città";
  var divClasses = `slgo-input slgo-autocomplete slgo-autocomplete-home slgo-autocomplete-google-places pt-1`;
  if (userGeolocated){
    placeHolder="        Posizione corrente";
    divClasses = `slgo-input slgo-autocomplete slgo-autocomplete-home slgo-autocomplete-google-places pt-1 slgo-autocomplete-compiled`;
  }

  return(
    <>
      <small className="slgo-light-text"><b>Vicino a</b></small>
      <div className={divClasses}>
        <AutoComplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          language='it'
          options={{
            types: ["geocode"],
            fields: ["geometry.location", "formatted_address"],
            componentRestrictions: {country: "it"}
          }}
          placeholder={placeHolder}
          onPlaceSelected={(place) => {
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
            setFormattedAddress(place.formatted_address);
            setUserGeolocated(true);
          }}
        />
      </div>
    </>
  );

}

export default PlacesInput;