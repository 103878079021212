import HomeSeo from "./HomeSeo";
import HowToUseIt from "./HowToUseIt";
import JoinUs from "./JoinUs";
import Resources from "./Resources/Resources";
import SearchSolutions from "./SearchSolutions/SearchSolutions";

const Home = () => {
  return(
    <>
      <HomeSeo />
      <SearchSolutions />
      <HowToUseIt />
      <Resources />
      <JoinUs />
    </>
  );
};

export default Home;