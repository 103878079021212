import { useState } from "react";

function getInitValues(publicCenter) {
  let localPrivate = false
  let localPublic = false
  let localAll = true

  if(publicCenter === "true") {
    localAll = false
    localPublic = true
  }

  if(publicCenter === "false") {
    localAll = false
    localPrivate = true
  }

  return({
    "all": localAll,
    "private": localPrivate,
    "public": localPublic
  });
}

const SearchFilterGeoRadio = ({setRadioValue, setRadioPublic, publicCenter}) => {

  const [tmpRadioValue, setTmpRadioValue] = useState(getInitValues(publicCenter))

  const handleAllOnChnage = () => {
    setTmpRadioValue({
      "all": true,
      "private": false,
      "public": false
    })
    setRadioValue('all')
    setRadioPublic('all')
  }

  const handlePrivateOnChnage = () => {
    setTmpRadioValue({
      "all": false,
      "private": true,
      "public": false
    })
    setRadioValue('private')
    setRadioPublic('false')
  }

  const handlePublicOnChnage = () => {
    setTmpRadioValue({
      "tmpAll": false,
      "tmpPrivate": false,
      "tmpPublic": true
    })
    setRadioValue('public')
    setRadioPublic('true')
  }

  return(
    <>
      <div className="col-md-8 flex-fill mb-4">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="input-all"
            value="tutti"
            onChange={handleAllOnChnage}
            checked={tmpRadioValue.all}
           />
          <label className="form-check-label" htmlFor="input-pubblico">tutti</label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="input-pubblico"
            value="pubblico"
            onChange={handlePublicOnChnage}
            checked={tmpRadioValue.public}
           />
          <label className="form-check-label" htmlFor="input-pubblico">pubblico</label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="input-privato"
            value="privato"
            onChange={handlePrivateOnChnage}
            checked={tmpRadioValue.private}
           />
          <label className="form-check-label" htmlFor="input-privato">privato</label>
        </div>
      </div>
    </>
  );
}

export default SearchFilterGeoRadio;