import Capitalize from "../../../utilities/Capitalize";
import { Helmet } from 'react-helmet-async';
import useFetch from "../../parts/useFetch";

const CentersCategorySeo = ({ title, description, regionId, provinceId, localityId, keyText }) => {

  const pageUrl = window.location.href;
  description = `Trova il centro medico più vicino a te per "${Capitalize(title)}"`;
  let baseTitle = `SaluteGo - Trova centro medico per "${Capitalize(title)}"`;
  let dynamicTitle = baseTitle;
  let url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/`;
  if (localityId) {
    url = url + `localities/${localityId}`;
  } else if (provinceId) {
    url = url + `provinces/${provinceId}`;
  } else if (regionId) {
    url = url + `regions/${regionId}`;
  }

  const {data, loading, error} = useFetch(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) console.log(error);
  if (!data) {
    console.log("No SEO info returned for title and description. Using default.")
  }

  if (keyText) {
    dynamicTitle = `SaluteGo - Trova centro medico per "${Capitalize(title)}" che contiene la parola "${keyText}"`;
    description = `Trova un centro medico più vicino a te per "${Capitalize(title)}" che contiene la parola "${keyText}"`;
  } else if (localityId && localityId != provinceId) {
    dynamicTitle = `SaluteGo - Ricerca centro medico ${Capitalize(data?.data.locality_name)} per "${Capitalize(title)}"`;
    description = `Trova un centro medico a ${Capitalize(data?.data.locality_name)} in provincia di ${Capitalize(data?.data.province_name)} per "${Capitalize(title)}"`;
  } else if (provinceId) {
    dynamicTitle = `SaluteGo - Ricerca centro medico ${Capitalize(data?.data.province_name)} per "${Capitalize(title)}"`;
    description = `Trova un centro medico in provincia di ${Capitalize(data?.data.province_name)} per "${Capitalize(title)}"`;
  } else if (regionId) {
    dynamicTitle = `SaluteGo - Ricerca centro medico ${Capitalize(data?.data.region_name)} per "${Capitalize(title)}"`;
    description = `Trova un centro medico in ${Capitalize(data?.data.region_name)} per "${Capitalize(title)}"`;
  }

  return(
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={dynamicTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={pageUrl} />
        <link rel="canonical" href={pageUrl} />
        <meta property="og:image" content="https://salutego.it/img/cart-prestazioni.jpg" />
        <meta name="twitter:image" content="https://salutego.it/img/cart-prestazioni.jpg" />
        <meta name="twitter:description" content={description} />
      </Helmet>
    </>
  );
}

export default CentersCategorySeo;