import { keyboard } from "@testing-library/user-event/dist/keyboard";
import Capitalize from "../../../utilities/Capitalize";
import { Helmet } from 'react-helmet-async';

const CentersServiceSeo = ({ categoryName, nearToAddress }) => {

  const pageUrl = window.location.href;
  const title = "SaluteGo - Trova centro medico " + nearToAddress;
  const description = "Trova un centro medico per " + Capitalize(categoryName) + " a " + nearToAddress;

  return(
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={pageUrl} />
        <link rel="canonical" href={pageUrl} />
        <meta property="og:image" content="https://salutego.it/img/cart-trova-centro-medico.jpg" />
        <meta name="twitter:image" content="https://salutego.it/img/cart-trova-centro-medico.jpg" />
        <meta name="twitter:description" content={description} />
      </Helmet>
    </>
  );
}

export default CentersServiceSeo;