export const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
      textTransform: 'capitalize',
      background: state.isSelected ? 'var(--slgo-blue-btn)' : 'var(--slgo-main-white)',
      color: state.isSelected ? 'var(--slgo-main-white)' : 'var(--slgo-main-black)',
      '&:hover' : {
        color: 'var(--slgo-main-white)',
        background: 'var(--slgo-main-blue)',
      },
    }),
    control: base => ({
      ...base,
      cursor: 'pointer',
      textTransform: 'capitalize',
      height: 52,
      minHeight: 52,
      paddingTop: 0,
      paddingRight: 7,
      paddingBottom: 0,
      paddingLeft: 12,
      borderRadius: 26,
    })
};