

import { useParams, useLocation } from "react-router-dom";
import React, { useState } from "react";
import CentersList from "../CentersList";
import CentersServiceSeo from "./CentersServiceSeo";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CentersService = () => {
  const { searchParams } = useParams();
  let query = useQuery();

  const [page, setPage] = useState(query.get('page'));

  return(
    <>
      <CentersServiceSeo categoryName={query.get('categoryName')} nearToAddress={query.get('address')} />
      {<CentersList
        categoryId={query.get('categoryId')}
        categoryName={query.get('categoryName')}
        nearToAddress={query.get('address')}
        lat={query.get('lat')}
        lng={query.get('lng')}
        page={page}
        setPage={setPage}
        caller='centersService'
      />}
    </>
  );
}

export default CentersService;