import { Link } from "react-router-dom";
import SignUpEmailForm from "./SignUpEmailForm";
import CheckIcon from "../../utilities/BootstrapIcons";
import SignUpSeo from "./SignUpSeo";
import { useState } from "react";

const SignUp = () => {

  const [planType, setPlanType] = useState('FREE')

  const onFreeClick = () => {
    setPlanType('FREE');
  }

  const onPremiumClick = () => {
    setPlanType('PREMIUM');
  }

  const onCustomClick = () => {
    setPlanType('CUSTOM');
  }
  

  return(
    <>
      <SignUpSeo />
      <div className="slider slider-top slider-top-grey">
        <div className="container">
          <div className="row row-flex justify-content-center align-items-center">
            <div className="col-md-7 flex-fill my-4">
              <h1 className="h1 slgo-small-title font-weight-bold">Il network SaluteGo</h1>
              <h2 className="slgo-small-title">Una realtà che conta <span className="font-weight-bold">migliaia</span> di ambulatori e centri medici</h2>
            </div>
            <div className="col-md-5"><img src="/img/image-banner-community.png" alt="SaluteGO" /></div>
          </div>
        </div>
      </div>
      <div className="signup-cta">
        <div className="container py-5">
          <div className="row row-flex justify-content-center align-items-center">
            <div className="col-md-4 pb-0">
              <img src="/img/image-packs.jpg" width="416" height="416" className="slgo-img-h-auto" alt="Ricerca prestazione vicino a te"/>
            </div>
            <div className="col-md-6 offset-md-1 pb-0">
              <h2 className="font-weight-bold slgo-small-title pb-4">Offri un servizio sanitario?</h2>
              <p>SaluteGo porta in primo piano le tue <strong>prestazioni sanitarie</strong>!<br/>
                Potrai sponsorizzare le tue prestazioni e il tuo centro medico, intercettando gli interessi degli utenti e limitando la visibilità in base 
                al raggio di azione a cui sei interessato!<br />
                Non importa che siano pubbliche, private o convenzionate con il Servizo Sanitario Nazionale: la soluzione migliore non è uguale per tutti, 
                ma dipende dalla particolare situazione di ogni persona.<br/></p>
            </div>
          </div>
        </div>
      </div>
      <div className="signup-plans">
        <div className="container pt-5">
          <div className="row row-flex">
            <div className="col-md-12">
              <h2 className="font-weight-bold slgo-small-title">Scegli il piano più adatto alle tue esigenze!</h2>
            </div>
          </div>
        </div>
        <div className="container pt-4">
          <div className="row row-flex justify-content-center">
            <div className="slgo-single-plan col-md-6 pb-4">
              <img src="/img/card-free.jpg" width="636" height="237" className="slgo-img-h-auto" alt="Piano SaluteGo Free"></img>
              <div className="p-4">
                <h5 className="font-weight-bold slgo-small-title mb-2">Free</h5>
                <p className="mb-4">Iscrivi il tuo centro medico e tutte le prestazioni che eroghi senza nessun costo.<br />
                Le tue prestazioni e il tuo centro saranno subito online e potrai farti trovare da miglia di utenti ogni giorno.</p>
                <a href="#form-req" className="btn btn-primary slgo-light-text" onClick={onFreeClick}>Iscriviti</a>
              </div>
            </div>
            <div className="slgo-single-plan col-md-6 pb-4">
              <img src="/img/card-premium.jpg" width="636" height="237" className="slgo-img-h-auto" alt="Piano SaluteGo Premium"></img>
              <div className="p-4">
                <h5 className="font-weight-bold slgo-small-title mb-2">Premium</h5>
                <p className="mb-4">Metti in evidenza le tue prestazioni e il tuo centro medico: catturerai l'attenzione di migliaia di visitatori che ogni giorno usano SaluteGo.<br />
                Riceverai inoltre statistiche ad-hoc sulle prestazioni e sul centro medico che decidi di sponsorizzare.</p>
                <a href="#form-req" className="btn btn-primary slgo-light-text" onClick={onPremiumClick}>Iscriviti</a>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-4 pb-5">
          <div className="row row-flex">
            <div className="col-md-12 table-responsive">
              <table className="table text-center">
                <thead>
                  <tr>
                    <th style={{ width: '50%' }}></th>
                    <th style={{ width: '25%' }}>FREE</th>
                    <th style={{ width: '25%' }}>PREMIUM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" className="text-start">Iscrizione Centro Medico</th>
                    <td><CheckIcon /></td>
                    <td><CheckIcon /></td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">Inserimento prestazioni del Centro Medico</th>
                    <td><CheckIcon /></td>
                    <td><CheckIcon /></td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">Indicizzazione su Google</th>
                    <td><CheckIcon /></td>
                    <td><CheckIcon /></td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">Pagina vetrina del Centro Medico</th>
                    <td><CheckIcon /></td>
                    <td><CheckIcon /></td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">Pagina vetrina di ogni singola prestazione del Centro Medico</th>
                    <td><CheckIcon /></td>
                    <td><CheckIcon /></td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">Visualizzazione nei risultati delle ricerche su SaluteGo</th>
                    <td><CheckIcon /></td>
                    <td><CheckIcon /></td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">Logo Centro Medico personalizzato</th>
                    <td>-</td>
                    <td><CheckIcon /></td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">Personalizzazione informazioni del Centro Medico</th>
                    <td>-</td>
                    <td><CheckIcon /></td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">Personalizzazione informazioni sulle prestazioni</th>
                    <td>-</td>
                    <td><CheckIcon /></td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">Posizione privilegiata delle prestazioni (desiderate e su base km) nei risultati di SaluteGo</th>
                    <td>-</td>
                    <td><CheckIcon /></td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">Posizione privilegiata del Centro Medico nei risultati di SaluteGo</th>
                    <td>-</td>
                    <td><CheckIcon /></td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">Report mensili su prestazioni / centri sponsorizzati</th>
                    <td>-</td>
                    <td><CheckIcon /></td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">Customer service prioritario</th>
                    <td>-</td>
                    <td><CheckIcon /></td>
                  </tr>
                  <tr className="slgo-no-mobile">
                    <th scope="row" className="text-start"></th>
                    <td><a href="#form-req" className="btn btn-primary slgo-light-text" onClick={onFreeClick}>Iscriviti</a></td>
                    <td><a href="#form-req" className="btn btn-primary slgo-light-text" onClick={onPremiumClick}>Iscriviti</a></td>
                  </tr>
                </tbody>
              </table>
              <a href="#form-req" className="btn btn-primary slgo-mobile slgo-light-text mt-3">Iscriviti</a>
            </div>
          </div>
        </div>
        <div className="container pt-4 pb-5">
          <div className="row row-flex text-center">
            <div className="col-md-12">
              <h4 className="">Vuoi un trattamento personalizzato?</h4>
              <a href="#form-req" className="slgo-small-title" onClick={onCustomClick}>Contattaci</a>
            </div>
          </div>
        </div>
      </div>
      <div className="signup-form" id="form-req">
        <div className="container py-5">
          <div className="row row-flex">
            <div className="col-md-12">
              <h2 className="font-weight-bold slgo-light-text mb-2">Compila questi dati e invia la tua richiesta</h2>
              <p className="slgo-light-text mb-4">Controlla il PIANO selezionato e invia la tua richiesta di iscrizione o le informazioni che desideri ricevere</p>
              <SignUpEmailForm planType={planType}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default SignUp;