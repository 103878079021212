import { Link } from "react-router-dom";

const JoinUs = () => {
  return (
    <>
      <div className="slgo-join-us">
        <div className="container pt-5">
          <div className="row row-flex justify-content-center align-items-center">
            <div className="col-md-4 pb-0">
              <h2 className="font-weight-bold slgo-small-title pb-4">Entra a far parte del<br />
                Network <span className="slgo-span-logo">salute<span>go</span></span></h2>
                <p>Offri un servizio sanitario?<br /><b>Iscriviti!</b> Potrai pubblicarlo gratuitamente!</p>
                <Link to="/offri-un-servizio-sanitario" className="btn btn-primary mt-3 mb-4 slgo-light-text">Collabora con noi</Link>
            </div>
            <div className="col-md-6 offset-md-1 pb-0">
              <img src="/img/image-banner-community.png" width="636" height="503" className="slgo-img-h-auto" alt="Entra in SaluteGO"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JoinUs;