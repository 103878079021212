import Capitalize from "../../utilities/Capitalize";
import FormatCenterName from "../../utilities/FormatCenterName";
import Titleize from "../../utilities/Titleize";
import { Link } from "react-router-dom";

const CenterServiceInfo = ({ data }) => {

  return(
    <>
      <h5><Link to={"/centro-medico/" + data?.data.slug} className="slgo-small-title font-weight-bold">{FormatCenterName(data?.data.center_name)}</Link></h5>
      <p>Indirizzo:&nbsp;
        <b>{Titleize(data?.data.geo_data.street_name)}, {data?.data.geo_data.street_number}</b>
      </p>
      <p>CAP:&nbsp;
        <b>{data?.data.geo_data.postal_code}</b>
      </p>
      <p>Città:&nbsp;
        <b>{Capitalize(data?.data.geo_data.locality_name)}</b>
      </p>
      <p>Provincia:&nbsp;
        <b>{Capitalize(data?.data.geo_data.province_name)} ({data?.data.geo_data.province_shortname.toUpperCase()})</b>
      </p>
      <div className="single-center-contacts single-center-contacts-small-card pt-4 pb-2">
        <p className="mb-3 pe-3">
          { data?.data.phone_number &&
            <a href={`tel://${data?.data.phone_number}`} className="btn btn-primary btn-no-arrow btn-only-icon slgo-btn-single-center slgo-btn-phone me-3"></a>
          }
          { data?.data.emails &&
            <a href={`mailto:${data?.data.emails}`} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-no-arrow btn-only-icon slgo-btn-single-center slgo-btn-email me-3"></a>
          }
          {/*<a href="#" target="_blank" rel="noreferrer" className="btn btn-primary btn-no-arrow btn-only-icon slgo-btn-single-center slgo-btn-website"></a>*/}
        </p>
      </div>
      <Link to={"/centro-medico/" + data?.data.slug} className="btn btn-primary mt-0 mb-5 slgo-light-text">Dettagli struttura</Link>
    </>
  );
  
}

export default CenterServiceInfo;