import { Link } from "react-router-dom";


const MenuHeader = () => {
  return(
    <>
      <li className="nav-item"><Link reloadDocument to="/" className="nav-link">Home</Link></li>
      <li className="nav-item"><Link reloadDocument to="il-servizio" className="nav-link">Il servizio</Link></li>
      <li className="nav-item"><Link reloadDocument to="prestazioni" className="nav-link">Prestazioni</Link></li>
      <li className="nav-item"><Link reloadDocument to="trova-centro-medico" className="nav-link">Centri medici</Link></li>
      <li className="nav-item"><Link reloadDocument to="offri-un-servizio-sanitario" className="nav-link">Offri un servizio sanitario?</Link></li>
    </>
  );
}

export default MenuHeader;