function FormatCenterName(name) {
	let formattedName = name
		.toLowerCase()
		.split(/(\s|-|\.)/)
		.map(word => {
			return word.length > 1 ? word.charAt(0).toUpperCase() + word.slice(1) : word;
		})
		.join('');
	if (formattedName.length > 0) {
	formattedName = formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
	}
	return formattedName;
}

export default FormatCenterName;