import CategoriesList from "./CategoriesList";
import CategoriesSliderTop from "./CategoriesSliderTop";
import CategoriesSeo from "./CategoriesSeo";

const Categories = ({ page }) => {
return(
  <>
    <CategoriesSeo />
    <CategoriesSliderTop />
    <CategoriesList page={page} />
  </>
);

}

export default Categories;