import { useContext } from "react";
import { EmailFormContext } from "./SignUpEmailForm";

const SignUpEmailFormFields = () => {

  const contextValue = useContext(EmailFormContext);

  return(
    <>
      <div className="col-md-6 form-input pb-4">
        <p>Nome <span className="required">*</span></p>
        <input
          id="input-name"
          type="text"
          value={contextValue.name}
          onInput={e => contextValue.setName(e.target.value)}
          placeholder=""
          autoComplete="off"
          required
        />
      </div>
      <div className="col-md-6 form-input pb-4">
        <p>Cognome <span className="required">*</span></p>
        <input
          id="input-surname"
          type="text"
          value={contextValue.surname}
          onInput={e => contextValue.setSurname(e.target.value)}
          placeholder=""
          autoComplete="off"
          required
        />
      </div>
      <div className="col-md-6 form-input pb-4">
        <p>Indirizzo e-mail <span className="required">*</span></p>
        <input
          id="input-email"
          type="email"
          value={contextValue.email}
          onInput={e => contextValue.setEmail(e.target.value)}
          placeholder=""
          autoComplete="off"
          required
        />
      </div>
      <div className="col-md-6 form-input pb-4">
        <p>Nome del Centro Medico <span className="required">*</span></p>
        <input
          id="input-center-name"
          type="text"
          value={contextValue.centerName}
          onInput={e => contextValue.setCenterName(e.target.value)}
          placeholder=""
          autoComplete="off"
          required
        />
      </div>
      <div className="col-md-12 form-input pb-4">
        <p>Lascia un messaggio</p>
        <textarea
          id="input-message"
          type="textarea"
          rows="4"
          cols="50"
          value={contextValue.message}
          onInput={e => contextValue.setMessage(e.target.value)}
          placeholder=""
          autoComplete="off"
        />
      </div>
      <div className="col-md-12 form-checkbox pb-4">
        <p>
          <input
            id="input-accept-privacy"
            type="checkbox"
            checked={contextValue.acceptedPrivacy}
            onChange={e => contextValue.setAcceptedPrivacy(e.target.checked)}
          />
          Acconsento al trattamento dei miei dati personali <span className="required">*</span>
        </p>
      </div>
      <p className="mb-3"><span className="required">*</span> I campi con l'asterisco sono obbligatori.</p>
    </>
  );
}

export default SignUpEmailFormFields;