const CenterServiceBookingInfoDetails = ({ data }) => {

  return(
    <>
      <div className="container slgo-mobile-w-94 p-0" id="prenota-ora">
        <div className="row g-5">
          <div className="col-md-12">
            <h5 className="slgo-light-text font-weight-bold pb-2">Prenota ora:</h5>
            {(data?.data.generic_phone_booking_number || data?.data.generic_phone_booking_number_by_mobile || data?.data.generic_phone_booking_number_by_mobile || data?.data.generic_system_booking_url) &&
              <div className="container container-center-actions px-5 py-4 mt-0 mb-4">
                <div className="row">
                  <div className="col-md-8 my-3">
                    <h4 className="font-weight-bold slgo-light-text">Tramite il Centro Unico di Prenotazione (CUP)</h4>
                  </div>
                  <div className="col-md-4 m-0">
                    {(data?.data.generic_phone_booking_number) &&
                      <p className="my-3"><a href={`tel://${data?.data.generic_phone_booking_number}`} className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-phone"><b>Chiama da fisso</b></a></p>
                    }
                    {(data?.data.generic_phone_booking_number_by_mobile) &&
                      <p className="my-3"><a href={`tel://${data?.data.generic_phone_booking_number_by_mobile}`} className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-mobile"><b>Chiama da cellulare</b></a></p>
                    }
                    {(data?.data.generic_email_booking_address) &&
                    <p className="my-3"><a href={`mailto:${data?.data.generic_email_booking_address}`} className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-email"><b>Prenota via email</b></a></p>
                    }
                    {(data?.data.generic_system_booking_url) &&
                      <p className="my-3"><a href={`${data?.data.generic_system_booking_url}`} target="_blank" className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-website"><b>Prenota online</b></a></p>
                    }  
                  </div>
                </div>
              </div>
            }
            {(data?.data.ssn_phone_booking_number || data?.data.ssn_phone_booking_number_by_mobile || data?.data.regional_phone_booking_number || data?.data.regional_phone_booking_number_by_mobile || data?.data.ssn_email_booking_address || data?.data.ssn_system_booking_url) &&
              <div className="container container-center-actions px-5 py-4 mt-0 mb-4">
                <div className="row">
                  <div className="col-md-8 my-3">
                    <h4 className="font-weight-bold slgo-light-text">Con il Servizio Sanitario Nazionale (SSN)</h4>
                  </div>
                  <div className="col-md-4 m-0">
                  {(data?.data.ssn_phone_booking_number) &&
                    <p className="my-3"><a href={`tel://${data?.data.ssn_phone_booking_number}`} className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-phone"><b>Chiama da fisso</b></a></p>
                  }
                  {(data?.data.ssn_phone_booking_number_by_mobile) &&
                    <p className="my-3"><a href={`tel://${data?.data.ssn_phone_booking_number_by_mobile}`} className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-mobile"><b>Chiama da cellulare</b></a></p>
                  }
                  {(data?.data.regional_phone_booking_number) &&
                    <p className="my-3"><a href={`tel://${data?.data.regional_phone_booking_number}`} className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-cup-phone"><b>Chiama da fisso il CUP Regionale</b></a></p>
                  }
                  {(data?.data.regional_phone_booking_number_by_mobile) &&
                    <p className="my-3"><a href={`tel://${data?.data.regional_phone_booking_number_by_mobile}`} className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-cup-mobile"><b>Chiama da cell il CUP Regionale</b></a></p>
                  }
                  {(data?.data.ssn_email_booking_address) &&
                    <p className="my-3"><a href={`mailto:${data?.data.ssn_email_booking_address}`} className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-email"><b>Prenota via email</b></a></p>
                  }  
                  {(data?.data.ssn_system_booking_url) &&
                    <p className="my-3"><a href={`${data?.data.ssn_system_booking_url}`} target="_blank" className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-website"><b>Prenota online</b></a></p>
                  }  
                  </div>
                </div>
              </div>
            }
            {(data?.data.private_phone_booking_number || data?.data.private_phone_booking_number_by_mobile || data?.data.private_email_booking_address || data?.data.private_system_booking_url) &&
              <div className="container container-center-actions px-5 py-4 mt-0 mb-4">
                <div className="row">
                  <div className="col-md-8 my-3">
                    <h4 className="font-weight-bold slgo-light-text">In Regime Privato o Semi-Privato (Intramoenia)</h4>
                  </div>
                  <div className="col-md-4 m-0">
                    {(data?.data.private_phone_booking_number) &&
                      <p className="my-3"><a href={`tel://${data?.data.private_phone_booking_number}`} className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-phone"><b>Chiama da fisso</b></a></p>
                    }
                    {(data?.data.private_phone_booking_number_by_mobile) &&
                      <p className="my-3"><a href={`tel://${data?.data.private_phone_booking_number_by_mobile}`} className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-mobile"><b>Chiama da cellulare</b></a></p>
                    }
                    {(data?.data.private_email_booking_address) &&
                      <p className="my-3"><a href={`mailto:${data?.data.private_email_booking_address}`} className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-email"><b>Prenota via email</b></a></p>
                    }
                    {(data?.data.private_system_booking_url) &&
                      <p className="my-3"><a href={`${data?.data.private_system_booking_url}`} target="_blank"  className="btn btn-primary btn-no-arrow slgo-btn-single-center-action slgo-btn-website"><b>Prenota online</b></a></p>
                    } 
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default CenterServiceBookingInfoDetails;