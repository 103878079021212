import Select from "react-select";
import useFetch from "../parts/useFetch";
import { customStyles } from './SelectStyles';
import SetDropdownDefaultValue from "../../utilities/SetDropdownDefaultValue";

const LocalityDropdown = ({provinceId, localityId, handleLocalityChange, setLocalityName}) => {

  let localityOptions = [];
  
  const url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/centers_locality_ids?province_ids=${provinceId}&do_not_page=true`;
  const { data, loading, error } = useFetch(url);

  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) console.log(error);
  if(!data?.data) {
    console.warn(`GET ${url} empty response`)
    return(
      <Select
        name="locality"
        options=""
        defaultValue=""
        placeholder={<div>Errore: lista non disponibile</div>}
        styles={customStyles}
      />
    );
  }

  data?.data.map(
    (locality) => {
      localityOptions.push({value: locality.id, label: locality.locality_name})
      if (locality.id === localityId) {
        setLocalityName(locality.locality_name)
      }
    }
  );

  const defaultValue = SetDropdownDefaultValue(localityOptions, localityId);

  return(
    <Select
      name="locality"
      options={localityOptions}
      defaultValue={defaultValue}
      placeholder={<div>Seleziona il comune</div>}
      styles={customStyles}
      onChange={(event) => handleLocalityChange(event)}
    />
  );

}

export default LocalityDropdown;