import SearchFilterGeo from "./SearchFilterGeo";
import { useState } from "react";
import SearchFilterKeyText from "./SearchFilterKeyText";

const SearchFilter = ({regionId, provinceId, localityId, keyText, activeTab, baseUrl, publicCenter, setPage}) => {

  let geoTab = true
  let keyTab = false
  if (activeTab === 'key') {
    geoTab = false
    keyTab = true
  }
  const [tmpActiveTab, setTmpActiveTab] = useState({
    "geoTab": geoTab,
    "keyTab": keyTab 
  });
  
  function showKeyTab() {
    setTmpActiveTab({
      "geoTab": false,
      "keyTab": true 
    })
  }

  function showGeoTab() {
    setTmpActiveTab({
      "geoTab": true,
      "keyTab": false 
    })
    // document.getElementById('keyInput').value = '';
  }

  return(
    <>
      <div className="slgo-filters-section">
        <div className="container">
          <div className="row row-flex justify-content-center align-items-center py-5">
            <div className="col-md-12 flex-fill">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className={"nav-link " + (tmpActiveTab.geoTab ? "active" : "")} id="nav-geo-tab" data-bs-toggle="tab" data-bs-target="#nav-geo" type="button" role="tab" aria-controls="nav-geo" aria-selected="true" onClick={showGeoTab}>Area geografica</button>
                  <button className={"nav-link " + (tmpActiveTab.keyTab ? "active" : "")} id="nav-key-tab" data-bs-toggle="tab" data-bs-target="#nav-key" type="button" role="tab" aria-controls="nav-key" aria-selected="false" onClick={showKeyTab}>Parola chiave</button>
                </div>
              </nav>
              <div className="tab-content nav-tabContent px-3 py-4">
                <SearchFilterKeyText activeKeyTab={tmpActiveTab.keyTab} keyText={keyText} baseUrl={baseUrl} setPage={setPage} />
                <SearchFilterGeo
                  activeGeoTab={tmpActiveTab.geoTab}
                  regionId={regionId}
                  provinceId={provinceId}
                  localityId={localityId}
                  baseUrl={baseUrl}
                  publicCenter={publicCenter}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchFilter;