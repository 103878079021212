import { Link } from "react-router-dom";
import AboutSeo from "./AboutSeo";

const About = () => {
  return (
    <>
      <AboutSeo />
      <div className="slider slider-top slider-top-orange">
        <div className="container">
          <div className="row row-flex justify-content-center align-items-center">
            <div className="col-md-7 flex-fill my-4">
              <h1 className="h1 slgo-small-title font-weight-bold">Il servizio SaluteGo</h1>
              <h2 className="slgo-small-title"><span className="font-weight-bold">Oltre 450.000</span> prestazioni sanitarie disponibili in tutta Italia</h2>
            </div>
            <div className="col-md-5"><img src="/img/header-image-contatti.png" alt="SaluteGO" /></div>{/* <img src="/img/header-image-[slug-pagina].png" /> */}
          </div>
        </div>
      </div>

      <div className="container container-about-intro py-4">
        <div className="row row-flex justify-content-center align-items-center">
          <div className="col-md-4 pb-0">
            <p>SaluteGo ti aiuta a trovare il <strong>centro medico</strong> più adatto alle tue esigenze! Non importa che sia pubblico, 
              privato o convenzionato, perché qui troverai la soluzione GIUSTA per te!</p>
            <Link to="/"><span className="font-weight-bold">Ricerca la Prestazione vicino a te</span></Link><br />
            <Link to="/prestazioni/"><span className="font-weight-bold">Ricerca per Prestazione sanitaria</span></Link><br />
            <Link to="/trova-centro-medico/"><span className="font-weight-bold">Ricerca per Centro Medico</span></Link>
          </div>
          <div className="col-md-4 offset-md-3 pb-0">
            <img src="/img/image-about.jpg" width="416" height="416" className="slgo-img-h-auto" alt="SaluteGO ti aiuta a trovare il centro medico"/>
          </div>
        </div>
      </div>

      <div className="about-search">
        <div className="container container-about-search py-5">
          <div className="row row-flex justify-content-center align-items-center">
            <div className="col-md-5 pb-0">
              <h2 className="font-weight-bold pb-4">Ricerca tra le prestazioni sanitarie vicino a te</h2>
              <p>La pagina principale ti permette di effettuare la ricerca per <strong>prestazione medica</strong>: conoscendo il nome della prestazione 
                da ricercare puoi ottenere una lista di <strong>centri medici</strong>, <strong>ambulatori</strong> e <strong>poliambulatori</strong> che la erogano.<br />
                La pagina di ricerca richiede solo l'inserimento di due informazioni:</p>
              <ol>
                <li>la prestazione sanitaria</li>
                <li>il luogo intorno al quale effettuare la ricerca</li>
              </ol>
              <p>La pagina dei risultati fornisce i centri medici che erogano il servizio, ordinati per distanza. 
                Cliccando su ciascun <strong>centro medico</strong> si raggiunge una pagina che mostra maggiori dettagli sulla 
                prestazione erogata. In fondo a questa pagine è anche presente la lista delle altre <strong>prestazioni sanitarie</strong> erogate 
                da quel particolare <strong>centro medico</strong>.</p>
              <Link to="/" className="btn btn-primary mt-3 mb-4 slgo-light-text">Ricerca la Prestazione vicino a te</Link>
            </div>
            <div className="col-md-4 offset-md-1 pb-0">
              <img src="/img/prestazioni.png" width="416" height="416" className="slgo-img-h-auto" alt="Ricerca prestazione vicino a te"/>
            </div>
          </div>
        </div>
        <hr />
        <div className="container container-about-search py-5">
          <div className="row row-flex justify-content-center align-items-center">
            <div className="col-md-4 pb-0">
              <img src="/img/contatti.png" width="416" height="416" className="slgo-img-h-auto" alt="Ricerca prestazione vicino a te"/>
            </div>
            <div className="col-md-5 offset-md-1 pb-0">
              <h2 className="font-weight-bold pb-4">Ricerca per Prestazione sanitaria</h2>
              <p>Attraverso il link di ricerca per <strong>Prestazioni mediche</strong> puoi raggiungere la pagina di ricerca per prestazione. 
                Selezionando la prestazione potrai vedere la lista di tutti i centri medici in Italia che la erogano.<br />È inoltre possibile 
                filtrare i <strong>centri medici</strong> per regione, provincia o comune, così da ottnere la lista di quelli che offrono 
                la prestazione ricercata nell'area geografica di tuo interesse.</p>
              <Link to="/prestazioni/" className="btn btn-primary mt-3 mb-4 slgo-light-text">Ricerca per Prestazione medica</Link><br />
            </div>
          </div>
        </div>
        <hr />
        <div className="container container-about-search py-5">
          <div className="row row-flex justify-content-center align-items-center">
            <div className="col-md-5 pb-0">
              <h2 className="font-weight-bold pb-4">Ricerca per Centro Medico</h2>
              <p>Nella barra dei menù trovi il link <strong>Centri medici</strong> dal quale puoi avviare una ricerca per singolo centro. 
                Da quì, puoi visualizzare tutti i centri medici e gli <strong>ambulatori</strong> per regione, provincia, comune e accedere alle pagine di dettaglio di ognuno di essi.</p>
              <Link to="/trova-centro-medico/" className="btn btn-primary mt-3 mb-4 slgo-light-text">Trova Centro Medico</Link>
            </div>
            <div className="col-md-4 offset-md-1 pb-0">
              <img src="/img/centri-medici.png" width="416" height="416" className="slgo-img-h-auto" alt="Ricerca centri medici e ambulatori vicino a te"/>
            </div>
          </div>
        </div>
      </div>
      
      <div className="about-coverage">
        <div className="container container-about-coverage py-5">
          <div className="row row-flex justify-content-center align-items-center">
            <div className="col-md-6 pb-0">
              <h2 className="font-weight-bold slgo-light-text pb-4">Copertura del servizio</h2>
              <p className="slgo-light-text">Allo stato attuale il servizio copre principalmente centri e prestaizoni offerti nelle regioni Lombardia, Lazio, Piemonte e Basilicata. Stiamo lavorando per estendere la copertura ad altre regioni.<br />
              Nel frattempo, puoi contribuire al miglioramento delle informazioni presenti sul nostro sito, inviandoci una mail per segnalarci nuovi centri e/o nuove prestazioni offerte dai centri già presenti.<br />
              Crediamo molto nell'idea di "rete sociale" e questa filosofia è ancora più utile nel contesto della Sanità Italiana.
              </p>
              <Link to="/offri-un-servizio-sanitario/" className="btn btn-primary mt-3 mb-4 slgo-light-text">Iscriviti al servizio</Link>
            </div>
            <div className="col-md-6 pb-0">
              <img src="/img/community.jpg" width="636" height="514" className="slgo-img-h-auto" alt="Ricerca prestazione vicino a te"/>
            </div>
          </div>
        </div>
      </div>
      
      
  </>
  );
};

export default About;