import { Helmet } from 'react-helmet-async';
import Capitalize from "../../utilities/Capitalize";

const SignUpSeo = (props) => {

  let pageUrl = window.location.href;
  const title = "SaluteGo - Il network che conta migliaia di centri medici e ambulatori";
  const description = "Offri un servizio sanitario? In SaluteGo potrai sponsorizzare le tue prestazioni sanitarie e il tuo centro medico, facendoti trovare da nuovi pazienti.";

  return(
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={pageUrl} />
        <link rel="canonical" href={pageUrl} />
        <meta property="og:image" content="https://salutego.it/img/card-offri-un-servizio-sanitario.jpg" />
        <meta name="twitter:image" content="https://salutego.it/img/card-offri-un-servizio-sanitario.jpg" />
        <meta name="twitter:description" content={description} />
      </Helmet>
    </>
  );
}

export default SignUpSeo;