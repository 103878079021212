import { useState, useContext } from "react";
import RegionDropdown from "./RegionDropdown";
import ProvinceDropdown from "./ProvinceDropdown";
import LocalityDropdown from "./LocalityDropdown";
import SearchFilterGeoRadio from "./SearchFilterGeoRadio";
import { SearchContext } from "./CentersBody";
import ScrollToElement from "../../utilities/ScrollToElement";

function buildGeoUrl(regionName, provinceName, localityName) {
  if (localityName) {
    return `nel-comune-di-${localityName}`;
  } else if (provinceName) {
    return `in-provincia-di-${provinceName}`;
  } else if (regionName) {
    return `in-${regionName}`;
  }
  return "";
}

const SearchFilterGeo = ({activeGeoTab, regionId, provinceId, localityId, baseUrl, publicCenter, setPage}) => {

  const contextValue = useContext(SearchContext);

  const [tmpRegionId, setTmpRegionId] = useState(regionId)
  const [tmpProvinceId, setTmpProvinceId] = useState(provinceId)
  const [tmpLocalityId, setTmpLocalityId] = useState(localityId)
  const [radioValue, setRadioValue] = useState("all")
  const [regionName, setRegionName] = useState("")
  const [provinceName, setProvinceName] = useState("")
  const [localityName, setLocalityName] = useState("")
  const [radioPublic, setRadioPublic] = useState(publicCenter)
  const pageBaseUrl = baseUrl

  const onClick = () => {
    contextValue.setRegionId(tmpRegionId)
    contextValue.setProvinceId(tmpProvinceId)
    contextValue.setLocalityId(tmpLocalityId)
    contextValue.setActiveTab("geo");
    contextValue.setRadioValue(radioValue);
    setPage('');

    ScrollToElement('center-map');

    let geoUrl = ""
    if (regionName !== "" || provinceName !== "" || localityName !== "") {
      geoUrl = '/' + buildGeoUrl(regionName, provinceName, localityName).replaceAll(' ', '+')
    }

    let radioUrl = ""
    if (radioPublic === 'true') {
      radioUrl = '?public=true'
    } else if (radioPublic === 'false') {
      radioUrl = '?public=false'
    }

    window.history.replaceState(null, "UpdatedUrl", `${pageBaseUrl}${geoUrl}${radioUrl}`);
  }

  const handleRegionChange = (event) => {
    setTmpRegionId(event.value);
    setTmpProvinceId(null);
    setTmpLocalityId(null);
    setRegionName(event.label);
    setProvinceName("");
    setLocalityName("");
  }

  const handleProvinceChange = (event) => {
    setTmpProvinceId(event.value);
    setTmpLocalityId(null);
    setProvinceName(event.label);
    setLocalityName("");
  }

  const handleLocalityChange = (event) => {
    setTmpLocalityId(event.value);
    setLocalityName(event.label);
  }

  const resetFilter = () => {
    setTmpRegionId(null);
    setTmpProvinceId(null);
    setTmpLocalityId(null);
    setRegionName("");
    setProvinceName("");
    setLocalityName("");
  }

  return(
    <>
      <div className={"tab-pane fade" + (activeGeoTab ? "show active" : "")} id="nav-geo" role="tabpanel" aria-labelledby="nav-geo-tab">
        <div className="container">
          <div className="row row-flex">
            <div className="col-md-4 react-select pb-3">
              <RegionDropdown 
                regionId={tmpRegionId}
                handleRegionChange={handleRegionChange}
                setRegionName={setRegionName}
              />
            </div>
            <div className="col-md-4 react-select pb-3">
              <ProvinceDropdown 
                regionId={tmpRegionId}
                provinceId={tmpProvinceId}
                handleProvinceChange={handleProvinceChange}
                setProvinceName={setProvinceName}
              />
            </div>
            <div className="col-md-4 react-select pb-3">
            <LocalityDropdown 
              provinceId={tmpProvinceId} 
              localityId={tmpLocalityId}
              handleLocalityChange={handleLocalityChange}
              setLocalityName={setLocalityName}
            />
            </div>
          </div>
          <div className="row row-flex">
            <SearchFilterGeoRadio setRadioValue={setRadioValue} setRadioPublic={setRadioPublic} publicCenter={publicCenter} />
            <div className="col-md-4 flex-fill">
              <button type="button" className="btn btn-primary btn-no-arrow float-end slgo-light-text slgo-btn-search" onClick={onClick}>Cerca</button>
            </div>
            <small className="slgo-reset-filters mt-4 mt-md-0" onClick={resetFilter}>Reset filtri</small>
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchFilterGeo;