function Titleize(str) {
  if (str) {
    str = str.toLowerCase();
    const words = str.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
  }
  return str;
}

export default Titleize;