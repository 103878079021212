import { Helmet } from "react-helmet-async";

const TermsOfUsage = () => {

  const pageUrl = window.location.href;
  const title = "SaluteGo - Condizioni di utilizzo";
  const description = "Condizioni di utilizzo del servizio SaluteGo.";

  return(
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={pageUrl} />
        <link rel="canonical" href={pageUrl} />
        <meta property="og:image" content="https://salutego.it/img/logo/salutego.png" />
        <meta name="twitter:image" content="https://salutego.it/img/logo/salutego.png" />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <div className="container container-blank-page">
        <div className="row py-5 my-5">
          <div className="col-sm-12">
            <h1 className="d-none d-sm-block text-uppercase">condizioni d'uso</h1>
            <ol>
              <li>
                <h3 className="text-uppercase pt-4">condizioni generali</h3>
                <p className="text-justify">
                  Le presenti condizioni generali di uso contengono una descrizione del funzionamento di questo Sito (<a href="https://www.salutego.it">https://www.salutego.it</a>) e regolano le relazioni tra salutego.it e gli utenti del Sito web.
                  <br />
                  Qualsiasi individuo può essere utente di questo Sito web, a condizione che abbia espressamente accettato le presenti condizioni generali di uso.<br />
                  Si considera che l’utente, per il fatto di utilizzare questo Sito, abbia espressamente compreso e accettato le presenti condizioni generali di uso in tutti i suoi elementi.<br />
                  Qualsiasi utente che abbia pienamente accettato queste condizioni generali di uso può accedere alle informazioni presenti su questo Sito web.<br />
                  salutego.it si riserva il diritto di modificare queste condizioni generali di uso in qualsiasi momento. L’utente è tenuto a consultare periodicamente le Condizioni d’uso per verificare eventuali cambiamenti. L’uso del Sito web in seguito alla modifica delle condizioni generali implica la loro accettazione da parte dell’utente.
                </p>
              </li>
              <li>
                <h3 className="text-uppercase pt-4">scopo del portale web</h3>
                <p className="text-justify">
                  Lo scopo di questo Sito web è la divulgazione di dati e informazioni ricavate tramite la creazione di più directories che includono medici e centri sanitari come ospedali, cliniche, case di cura e associazioni (di seguito chiamati “fornitori di servizi sanitari”). Agli utenti di questo Sito (di seguito chiamati “utenti”) è consentito l’accesso alle informazioni in merito a questi medici e centri sanitari come ospedali, cliniche, case di cura e associazioni.
                </p>
              </li>
              <li>
                <h3 className="text-uppercase pt-4">uso del portale web</h3>
                <p className="text-justify">
                  Il Sito può essere utilizzato soltanto per scopi personali e non commerciali e per gli usi consentiti dalla legge da soggetti alla ricerca di informazioni sanitarie.<br />
                  salutego.it proibisce le seguenti modalità di utilizzo del Sito:<br />
                  (a) corruzione o alterazione di informazioni in esso pubblicate per scopi illeciti, anticoncorrenziali, o comunque non conformi al tipo di servizio offerto dal Sito;<br />
                  (b) utilizzo di qualsiasi dispositivo o software che possa interferire con la funzionalità del Sito o comunque con qualunque attività condotta sul Sito stesso;<br />
                  (c) intraprendere qualunque azione che imponga un irragionevole o sproporzionato carico sull'infrastruttura del Sito;
                  (d) usare o cercare di utilizzare applicativi software o altri dispositivi di qualunque natura (come browsers, spiders, robots, ...) per navigare o esplorare questo Sito;<br />
                  (e) cercare di decifrare, decodificare, smontare o ricostruire il contenuto del software utilizzato per strutturare il Sito.
                  Gli Utenti, in generale, non devono violare o tentare di violare i sistemi di sicurezza del Sito. Le violazioni delle misure di sicurezza del sistema possono comportare responsabilità civile o penale. Lo staff di salutego.it provvederà pertanto ad informare le competenti Autorità relativamente a quei comportamenti che potrebbero risultare in violazione di leggi penali o civili e potrà, ove richiesto, cooperare con le competenti Autorità nel perseguire gli Utenti coinvolti in tali violazioni.
                </p>
              </li>
              <li>
                <h3 className="text-uppercase pt-4">origine e proprietà dei dati</h3>
                <p className="text-justify">Le informazioni presenti sul Sito salutego.it possono provenire dalle seguenti fonti:</p>
                <ul className="upper-alpha">
                  <li>
                    <p>informazioni di pubblico dominio;</p>
                  </li>
                  <li>
                    <p>portali web con contenuti di tipo “Open Data”;</p>
                  </li>
                  <li>
                    <p>fornitori di servizi sanitari;</p>
                  </li>
                  <li>
                    <p>segnalazioni spontanee di utenti di questo Sito.</p>
                  </li>
                </ul>
                <p className="text-justify">
                  Per quanto riguarda le fonti riferibili al punto B, è bene precisare che una parte delle informazioni pubblicate dal Sito salutego.it è stata ottenute tramite complesse operazioni di correzione, filtraggio, organizzazione, elaborazione e manipolazione dagli Open Data messi a disposizione da:
                </p>
                <br />
                <div className="table-responsive-sm">
                  <table className="table">
                    <thead>
                        <th><p><strong>Licenziante</strong></p></th>
                        <th><p><strong>Tipo Licenza</strong></p></th>
                        <th><p><strong>Link</strong></p></th></thead>
                    <tbody>
                      <tr>
                        <td><p>Regione Lombardia</p></td>
                        <td><a href="http://www.dati.gov.it/iodl/2.0/">IODLv2</a></td>
                        <td><a href="https://dati.lombardia.it/">https://dati.lombardia.it/</a></td>
                      </tr>
                      <tr>
                        <td><p>Regione Piemonte</p></td>
                        <td><a href="https://creativecommons.org/licenses/by/2.5/it/">CC BY 2.5 IT</a></td>
                        <td><a href="http://www.dati.piemonte.it">www.dati.piemonte.it</a></td>
                      </tr>
                      <tr>
                        <td><p>Asp Lazio</p></td>
                        <td><a href="http://www.dati.gov.it/iodl/2.0/">IODLv2</a></td>
                        <td><a href="http://www.asplazio.it/opendata/dati_sias.php">www.asplazio.it/opendata/dati_sias.php</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <p className="text-justify">
                  Per tali dati si applicano le osservazioni e le limitazioni in materia di diritto d'autore previste da tali tipi di licenze.<br />
                  Per le fonti riferibili al punto C, le informazioni fornite dai fornitori di servizi sanitari dipendono esclusivamente dagli stessi.<br />
                  salutego.it si impegna ad effettuare, nel limite delle sue possibilità, controlli di veridicità e consistenza delle informazioni divulgate da fornitori di servizi sanitari e utenti prima della pubblicazione online.<br />
                  Non verranno pubblicate informazioni che vengano accertate come errate o mendaci.<br />
                  Salvi i limiti e le esclusioni stabilite dalla legge applicabile (in particolare per quanto riguarda le normative sulle opere derivate da dati coperti da licenze IODL e CC), i contenuti di questo Sito sono di proprietà personale del team di salutego.it e sono protetti dalla legislazione nazionale e dalle norme internazionali in materia di proprietà industriale e intellettuale.<br />
                  Tale protezione si estende alla sua composizione, elaborazione, progettazione, configurazione, software, testo, grafici, loghi, icone, immagini, file, video, audio, ecc.<br />
                  Salvi le esclusioni e limitazioni stabilite dalla legge, gli utilizzatori del Sito web si impegnano a rispettare tali diritti e, quindi, a non copiare, riprodurre, modificare, distribuire, trasmettere, pubblicare, visualizzare o rappresentare nessuno di tali contenuti, né a effettuare qualsiasi altra attività che possa violare i diritti di proprietà del team di salutego.it.
                </p>
              </li>
              <li>
                <h3 className="text-uppercase pt-4">limitazioni di responsabilità</h3>
                <p className="text-justify">
                  Sebbene lo staff di salutego.it faccia ogni sforzo per garantire un funzionamento affidabile del servizio e la correttezza delle informazioni, è possibile che a volte ci siano errori o imprecisioni, o ci sia un eventuale problema di natura tecnica che impedisce o ostacola il funzionamento del Sito, per cui salutego.it non garantisce il funzionamento del Sito web o le informazioni, contenuti o software inclusi in esso.<br />
                  In particolare lo staff di salutego.it non è responsabile per la mancanza di accuratezza, completezza, aggiornamento e/o esattezza dei dati o informazioni su questo Sito web, in quanto forniti a salutego.it dai fornitori di servizi sanitari, da utenti o da terze parti.<br />
                  salutego.it svolge un servizio finalizzato a far incontrare la richiesta di prestazione medica specialistica da parte degli utenti con i fornitori di servizi sanitari presenti nel Sito. salutego.it non svolge alcun tipo di attività medica, né è in alcun modo coinvolta nella fase di prenotazione ed effettuazione del servizio medico. Pertanto, il team di salutego.it non può essere in alcun modo considerato responsabile di eventuali danni subiti dall'utente a seguito della prestazione o della mancata prestazione identificata per mezzo del Sito o di ritardi da parte del medico nell'erogazione della prestazione. Chi consulta ed utilizza il Sito si assume tutti i rischi relativi al rapporto con i medici.<br />
                  salutego.it non è coinvolta e non può essere coinvolta nei rapporti tra utenti, medici e centri sanitari anche se gli stessi hanno origine dal Sito; perciò, nel caso in cui dovessero sorgere delle controversie tra queste parti, i partecipanti al progetto salutego.it non saranno ritenuti responsabili per nessun danno l'utente dovesse subire in conseguenza dell’operato dei medici.<br />
                  <span className="text-uppercase">salvi i limiti stabiliti dalla legge applicabile, né i responsabili del sito salutego.it né i licenzianti degli open data, saranno in alcun caso responsabili nei confronti degli utenti o utlizzatori del servizio offerto a qualunque titolo per alcun tipo di danno, sia esso speciale, incidentale, consequenziale, punitivo od esemplare, derivante dalla presente licenza o dall’uso dell’opera, anche nel caso in cui il licenziante sia stato edotto sulla possibilità di tali danni. nessuna clausola di questa licenza esclude o limita la responsabilità nel caso in cui questa dipenda da dolo o colpa grave.</span><br />
                  Le informazioni presentate in <a href="www.salutego.it">www.salutego.it</a> non sostituiscono in alcun modo il rapporto tra utenti di questo Sito e i propri medici.<br />
                  Tutti gli utenti di questo Sito (utenti finali e fornitori di servizi sanitari) si impegnano a manlevare e tenere indenne lo staff di salutego.it da qualsiasi obbligo che possa originarsi a loro carico in relazione all'utilizzo del Sito e del materiale in esso pubblicato o alla violazione delle presenti Condizioni D'Uso.<br />
                  Il Sito può contenere links con altri siti. Lo staff di salutego.it non è in alcun modo responsabile per i contenuti di questi siti.
                </p>
              </li>
              <li>
                <h3 className="text-uppercase pt-4">dati degli utenti e dei fornitori dei servizi sanitari</h3>
                <p className="text-justify">
                  L’utente e il fornitore di servizi sanitari si impegnano a fornire dati veritieri, completi e precisi, e si impegnano a notificare a insalute.it qualunque cambiamento occorso agli stessi nel più breve tempo possibile, al fine di evitare i problemi che possono derivare dal fatto che salutego.it disponga di dati incorretti o non aggiornati.
                </p>
              </li>
              <li>
              <h3 className="text-uppercase pt-4">database degli utenti del sito</h3>
                <p className="text-justify">
                  Gli utenti di questo Sito web e i fornitori di servizi sanitari accettano espressamente che i dati raccolti da questo Sito, e altri che vengano ottenuti posteriormente, vengano immagazzinati da salutego.it in una raccolta di dati di carattere personale di proprietà del team di salutego.it.<br />
                  Questi dati saranno utilizzati da salutego.it per fornire informazioni (comprese azioni di promozione e commercializzazione, anche di terze parti) e servizi all’utente in questione, uso per cui l’utente stesso dà il suo espresso consenso.<br />
                  salutego.it non trasferirà mai dati a terzi, al fine di eseguire azioni di marketing volte a promuovere i loro prodotti o servizi.<br />
                  In conformità con l’art. 7 del D.Lgs. 30 Giugno 2003 n. 196 Codice in materia di protezione dei dati personali, è previsto che i diritti di accesso, modifica e cancellazione dei dati raccolti nel file personale di un utente possano essere esercitati dall’utente stesso contattando salutego.it tramite l’indirizzo di posta elettronica <a href="mailto:privacy@salutego.it">privacy@salutego.it</a>.
                </p>
              </li>
              <li>
                <h3 className="text-uppercase pt-4">diritto applicabile e giurisdizionale</h3>
                <p className="text-justify">
                  Queste condizioni generali d’ uso sono disciplinate dal diritto italiano, e sia i partecipanti al progetto salutego.it, sia gli utenti del Sito, si sottomettono alla giurisdizione del Tribunale della città di Milano (Italia) per qualsiasi problema che potrebbe sorgere in merito all’interpretazione, esecuzione o violazione di queste condizioni generali di uso e ai suoi effetti.<br />
                  In nessun caso lo staff di salutego.it potrà essere ritenuto responsabile per danni morali, perdita di profitti e/o di qualsiasi altra conseguenza diretta o indiretta sofferta dagli utenti o dagli inserzionisti.<br />
                  Il team di salutego.it non è responsabile delle azioni svolte direttamente dagli utenti o dagli inserzionisti tramite il Sito web.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsOfUsage;