import MenuFooter from "./MenuFooter";
import { Link } from "react-router-dom";
import PrivacyPolicy from "../PrivacyPolicy";

const Footer = () => {
  return(
    <>
      <div className="container-fluid footer-container slgo-light-text">
        <div className="row">
          <div className="col col-md-12">
            <div className="container">
              <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-4 pt-5 mt-4 pb-5">
                <div className="slgo-footer slgo-footer-col-logo col col-xl-3 col-md-4 col-sm-12 mb-3">
                  <a href="/" className="d-flex align-items-center footer-brand mb-3">
                    <img src="/img/logo/logo-white.svg" width="180" height="45" alt="SaluteGO"/>
                  </a>
                </div>

                <div className="slgo-footer slgo-footer-col-menu col col-xl-3 col-md-4 col-sm-12 mb-3">
                  <ul className="nav flex-column">
                    <MenuFooter />
                  </ul>
                </div>

                <div className="slgo-footer slgo-footer-col-menu2 col col-xl-3 col-md-4 col-sm-12 mb-3">
                  <ul className="nav flex-column">        
                    <li className="nav-item mb-2">
                      <Link to="condizioni-uso" className="nav-link p-0 slgo-light-text">Condizioni d'Uso</Link>
                    </li>
                    <li className="nav-item mb-2">
                      <PrivacyPolicy />
                    </li>
                    {/* <li className="nav-item mb-2">
                      <CookieSetting />
                    </li> */}
                  </ul>
                </div>

                <div className="slgo-footer slgo-footer-col-social col col-xl-3 col-md-12 col-sm-12 mb-3">
                  <p>Social</p>
                  <a href="" target="_blank" className="me-2 link-linkedin"></a>
                  <a href="" target="_blank" className="me-2 link-instagram"></a>
                </div>
              </footer>

              {/*
              <div className="slgo-footer slgo-footer-copy d-flex flex-column flex-sm-row justify-content-between mt-4 border-top">
                <p className="my-3"><small>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed diam non nimbus ut lore dolore eiusmod tincidunt.</small></p>
              </div>
              */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;