import SearchFilter from "./SearchFilter";
import { useState, createContext } from "react";
import CentersList from "./CentersList";

export const SearchContext = createContext();

function getInitRadioValue(publicCenter) {
  if (publicCenter === "true") return 'public'
  if (publicCenter === "false") return 'private'
  return 'all';
}

const CentersBody = (props) => {

  const [regionId, setRegionId] = useState(props.regionId)
  const [provinceId, setProvinceId] = useState(props.provinceId)
  const [localityId, setLocalityId] = useState(props.localityId)
  const [radioValue, setRadioValue] = useState(getInitRadioValue(props.publicCenter))
  const [keyText, setKeyText] = useState(props.keyText)
  const [activeTab, setActiveTab] = useState(props.activeTab);
  const [page, setPage] = useState(props.page);

  const contextValue = {
    setRegionId,
    setProvinceId,
    setLocalityId,
    setKeyText,
    setRadioValue,
    setActiveTab
  };
  return(
    <>
      <SearchContext.Provider value={contextValue}>
        <SearchFilter
          regionId={regionId}
          provinceId={provinceId}
          localityId={localityId}
          keyText={keyText}
          activeTab={activeTab}
          baseUrl={props.baseUrl}
          publicCenter={props.publicCenter}
          setPage={setPage}
        />
        {<CentersList
          setPage={setPage}
          page={page}
          regionId={regionId}
          provinceId={provinceId}
          localityId={localityId}
          radioValue={radioValue}
          keyText={keyText}
          activeTab={activeTab}
          categorySlug={props.categorySlug}
          categoryName={props.categoryName}
          caller={props.caller}
        />}
      </SearchContext.Provider>
    </>
  );
}

export default CentersBody;