import { Link } from "react-router-dom";

const MenuFooter = () => {
  return(
    <>
      <li className="nav-item mb-2">
        <Link reloadDocument to="" className="nav-link p-0 slgo-light-text">Home</Link>
      </li>
      <li className="nav-item mb-2">
        <Link reloadDocument to="il-servizio" className="nav-link p-0 slgo-light-text">Il Servizio</Link>
      </li>
      <li className="nav-item mb-2">
        <Link reloadDocument to="prestazioni" className="nav-link p-0 slgo-light-text">Prestazioni sanitarie</Link>
      </li>
      <li className="nav-item mb-2">
        <Link reloadDocument to="trova-centro-medico" className="nav-link p-0 slgo-light-text">Centri medici e Ambulatori</Link>
      </li>
      <li className="nav-item mb-2">
        <Link reloadDocument to="offri-un-servizio-sanitario" className="nav-link p-0 slgo-light-text">Offri un servizio sanitario?</Link>
      </li>
    </>
  );
}

export default MenuFooter;