import { Helmet } from 'react-helmet-async';

const AboutSeo = (props) => {

  const pageUrl = window.location.href;
  const title = "SaluteGo - Oltre 450.000 prestazioni sanitarie in tutta Italia";
  const description = "SaluteGo ti aiuta a trovare il centro medico più adatto alle tue esigenze, filtrando per specialista e per prestazioni sanitarie.";

  return(
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={pageUrl} />
        <link rel="canonical" href={pageUrl} />
        <meta property="og:image" content="https://salutego.it/img/card-il-servizio.jpg" />
        <meta name="twitter:image" content="https://salutego.it/img/card-il-servizio.jpg" />
        <meta name="twitter:description" content={description} />
      </Helmet>
    </>
  );
}

export default AboutSeo;