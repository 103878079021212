function BuildFullAddress (props) {
  return(
    props.localityName.toUpperCase() + " - "
    + props.streetName + ", "
    + props.streetNumber + " - "
    + props.postalCode + " - ("
    + props.provinceName + ") - " 
    + props.regionName
  );
}

export default BuildFullAddress;