function FindCenter(markers) {
  if(markers.length === 1) return {
    latitude: markers[0].latitude,
    longitude: markers[0].longitude
  }
  const lats = markers.map(m => m.latitude);
  const lngs = markers.map(m => m.longitude);
  return {
    latitude: (Math.min(...lats) + Math.max(...lats)) / 2,
    longitude: (Math.min(...lngs) + Math.max(...lngs)) / 2
  };
}

export default FindCenter