import { Helmet } from 'react-helmet-async';

const HomeSeo = (props) => {

  let pageUrl = window.location.href;

  const title = "SaluteGo - Le prestazioni sanitarie nel centro medico più vicino a te";
  const description = "Trova il centro medico più vicino a te, le prestazioni sanitarie che ti interessano, oppure uno specialista di fiducia nella tua città.";

  return(
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={pageUrl} />
        <link rel="canonical" href={pageUrl} />
        <meta property="og:image" content="https://salutego.it/img/logo/salutego.png" />
        <meta name="twitter:image" content="https://salutego.it/img/logo/salutego.png" />
        <meta name="twitter:description" content={description} />
      </Helmet>
    </>
  );
}

export default HomeSeo;