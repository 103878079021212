import SliderTop from "./SliderTop";
import CentersBody from "./CentersBody";

const Centers = ({regionId, provinceId, localityId, keyText, activeTab, publicCenter, page}) => {

  const baseUrl = window.location.origin.toString() + '/trova-centro-medico';

  return( 
    <>
      <SliderTop title="Centri medici" desc=""/>
      <CentersBody regionId={regionId} provinceId={provinceId} localityId={localityId} keyText={keyText} activeTab={activeTab} baseUrl={baseUrl} publicCenter={publicCenter} page={page} caller="centers" />
    </>
  );
}

export default Centers;