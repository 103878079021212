import { Outlet } from "react-router-dom";
import PageHeader from "./parts/PageHeader";
import Footer from "./parts/Footer";
import { Helmet } from 'react-helmet-async';

const Layout = () => {
  return(
    <>
      <Helmet>
        { (process.env.REACT_APP_ENV !== 'production') &&
          <meta name="robots" content="noindex, nofollow" />
        }
      </Helmet>
      <main id="main" >{/* <main id="[slug-pagina]" /> */}
      <PageHeader />
      <Outlet />
      <Footer />
      </main>
    </>
  );
}

export default Layout;