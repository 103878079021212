import React, { useState } from "react"
import { Map, Marker, ZoomControl } from "pigeon-maps"
import FindCenter from "../../utilities/FindCenter"
import LatLongDistance from "../../utilities/LatLongDistance";

// Here I am using https://pigeon-maps.js.org/ (really simple!)
// but the https://mmomtchev.github.io/rlayers/ is very cool too

function defineZoom(mapCenter, markersLatLong) {
  // 13 is the defaultZoom for one element
  // Italy is 1200 km long, so after 600km the zoom is set to 5 (all Italy)
  if(markersLatLong.length < 2) return 13
  const distances = [];
  markersLatLong.map((markerLatLong) => (
    distances.push(LatLongDistance(mapCenter.latitude, mapCenter.longitude, markerLatLong.latitude, markerLatLong.longitude))
  ))
  const maxDistance = Math.max(...distances)
  if (maxDistance > 600) return 4
  if (maxDistance > 300) return 5
  if (maxDistance > 100) return 6
  if (maxDistance > 70) return 7
  if (maxDistance > 55) return 8
  if (maxDistance > 40) return 9
  if (maxDistance > 25) return 10
  if (maxDistance > 15) return 11
  if (maxDistance > 2) return 12
  return 13
}

const ReMap = ({markersLatLong, latUser, lngUser}) => {

  const mapCenter = FindCenter(markersLatLong)
  var defaultZoom = defineZoom(mapCenter, markersLatLong)

  const [clickedMarker, setClickedMarker] = useState(null);
  const handleMarkerClick = (index) => {
    setClickedMarker(index);
    window.location.href = "#anchor-center-" + index;
  };

  return (
    <div id="center-map" className="mb-3">
      <Map
        height={350}
        defaultCenter={[mapCenter.latitude, mapCenter.longitude]}
        defaultZoom={defaultZoom}
        zoomSnap={false}
        zoomOnMouseWheel={false}
      >
        <Marker
          width={50}
          anchor={[parseFloat(latUser), parseFloat(lngUser)]}
          color={"var(--slgo-main-green)"}
        />
        {markersLatLong.map((markerLatLong) => (
          <Marker
            key={`marker${markerLatLong.index}`}
            width={50}
            anchor={[markerLatLong.latitude, markerLatLong.longitude]}
            color={"var(--slgo-main-blue)"}
            onClick={() => handleMarkerClick(markerLatLong.index)}
          />
        ))}
        <ZoomControl />
      </Map>
    </div>
  )
}

export default ReMap;