import { Link } from "react-router-dom";

const CtaCooperate = (props) => {

  return(
    <>
      <div className="container pb-5">
        <div className="row">
          <div className="col-md-12">
            <div className="container slider cta-cooperate px-0 pt-3 px-sm-5">
              <div className="row row-flex justify-content-center align-items-center px-4">
                <div className="col-md-6 flex-fill pt-3 pb-0 pt-sm-5 pb-md-4 my-0">
                    <h2 className="font-weight-bold slgo-light-text">Offri un servizio sanitario in questa zona? Iscriviti!<br />
                      Potrai pubblicarlo gratuitamente</h2>
                    <Link to="/offri-un-servizio-sanitario" className="btn btn-primary mt-3 mb-4 slgo-light-text">Collabora con noi</Link>
                </div>
                <div className="col-md-6 pt-0 pt-sm-5"><img src="/img/header-image-community.png" width="564" height="377" className="slgo-img-h-auto" alt="Offri un servizio sanitario?" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CtaCooperate;