import MenuHeader from "./MenuHeader";

const PageHeader = () => {
  return(
    <>
      <nav className="navbar navbar-expand-lg navbar-top fixed-top">
        <div className="container">
        <a className="navbar-brand logo-desktop" href="/">
            <img src="/img/logo/logo.svg" width="180" height="45" alt="SaluteGO"/>
          </a>
          <a className="navbar-brand logo-mobile" href="/">
            <img src="/img/logo/logo-mobile.svg" width="45" height="43" alt="SaluteGO"/>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="d-flex d-none d-lg-block">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <MenuHeader />
            </ul>
          </div>
        </div>
      </nav>

      <div className="offcanvas offcanvas-end offcanvas-mobile-menu" tabIndex="-1" id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel">
        <div className="offcanvas-header">
          <h5 id="offcanvasMenuLabel"></h5>
          <button type="button" className="btn-close btn-close-white text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
            <MenuHeader />
          </ul>
        </div>
      </div>
    </>
  );
}

export default PageHeader;