import { Helmet } from 'react-helmet-async';
import Titleize from '../../utilities/Titleize';

const CenterSeo = ({ centerName, locality, province }) => {
  let title = `SaluteGo - Centro Medico ${Titleize(centerName)} ${Titleize(locality)} in provincia di ${Titleize(province)}`
  let description = `${Titleize(centerName)} - Centro medico a ${Titleize(locality)} in provincia di ${Titleize(province)}`

  if (locality === province) {
    title = `SaluteGo - Centro Medico ${Titleize(centerName)} a ${Titleize(locality)}`
    description = `${Titleize(centerName)} - Centro medico a ${Titleize(locality)}`
  }
  const pageUrl = window.location.href;

  return(
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={pageUrl} />
        <link rel="canonical" href={pageUrl} />
        <meta property="og:image" content="https://salutego.it/img/logo/salutego.png" />
        <meta name="twitter:image" content="https://salutego.it/img/logo/salutego.png" />
        <meta name="twitter:description" content={description} />
      </Helmet>
    </>
  );
}

export default CenterSeo;