import { useContext } from "react";
import { EmailFormContext } from "./SignUpEmailForm";

const SignUpEmailFormRadio = () => {

  const contextValue = useContext(EmailFormContext);

  const handleFreeOnChnage = () => {
    contextValue.setRadioValue({
      "free": true,
      "premium": false,
      "custom": false
    })
  }

  const handlePremiumOnChnage = () => {
    contextValue.setRadioValue({
      "free": false,
      "premium": true,
      "custom": false
    })
  }

  const handleCustomOnChnage = () => {
    contextValue.setRadioValue({
      "free": false,
      "premium": false,
      "custom": true
    })
  }

  return(
    <>
      <div className="col-md-12 pb-4">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="input-free"
            value="free"
            onChange={handleFreeOnChnage}
            checked={contextValue.radioValue.free}
          />
          <label className="form-check-label" htmlFor="input-free">FREE</label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="input-premium"
            value="premium"
            onChange={handlePremiumOnChnage}
            checked={contextValue.radioValue.premium}
          />
          <label className="form-check-label" htmlFor="input-premium">PREMIUM</label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="input-personalizzato"
            value="personalizzato"
            onChange={handleCustomOnChnage}
            checked={contextValue.radioValue.custom}
          />
          <label className="form-check-label" htmlFor="input-personalizzato">PERSONALIZZATO</label>
        </div>
      </div>
    </>
  );
}

export default SignUpEmailFormRadio;