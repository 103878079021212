import ReMap from "../parts/ReMap";
import useFetch from "../parts/useFetch";
import CenterServiceInfo from "./CenterServiceInfo";
import { Helmet } from 'react-helmet-async';
import Capitalize from "../../utilities/Capitalize";
import FormatCenterName from "../../utilities/FormatCenterName";

const CenterService = ({ centerId, serviceName }) => {

  let url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/centers/${centerId}`;
  let pageUrl = window.location.href;

  const {data, loading, error} = useFetch(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) console.log(error);
  if (!data) {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>
      </div>
      </>
    );
  }

  const centerName = Capitalize(data?.data.center_name.split('-')[0].trim()); // no province
  const provinceName = Capitalize(data?.data.geo_data.province_name);
  const regionName = Capitalize(data?.data.geo_data.region_name);
  const localityName = Capitalize(data?.data.geo_data.locality_name);

  let description = "";
  let title = `SaluteGo - "${Capitalize(serviceName)}" ${Capitalize(localityName)} presso ${FormatCenterName(centerName)}`;

  if (centerName && provinceName && serviceName) {
    if (provinceName === localityName) {
      description = `"${Capitalize(serviceName)}" a ${FormatCenterName(centerName)}, Centro Medico a ${Capitalize(localityName)} in ${regionName}`;
    } else {
      description = `"${Capitalize(serviceName)}" a ${FormatCenterName(centerName)}, Centro Medico a ${Capitalize(localityName)} in provincia di ${Capitalize(provinceName)}`;
    }
  }

  return(
    <>
      <Helmet>
      <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={pageUrl} />
        <link rel="canonical" href={pageUrl} />
        <meta property="og:image" content="https://salutego.it/img/cart-trova-centro-medico.jpg" />
        <meta name="twitter:image" content="https://salutego.it/img/cart-trova-centro-medico.jpg" />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <div className="col-lg-4">
        <div className="container container-top container-h-100 slgo-mobile-w-94 p-0 mt-0 mb-0 mt-md-5 mt-md-5">
          <div className="row g-5">
            <div className="col-md-12 m-0">
              <ReMap markersLatLong={[{latitude: data?.data.geo_data.latitude, longitude: data?.data.geo_data.longitude}]} />
              <div className="col-md-12 px-4 py-3">
                <CenterServiceInfo data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}

export default CenterService;